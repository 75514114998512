/* CustomLightBox */
.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Dark overlay with opacity */
    backdrop-filter: blur(5px); /* Background blur effect */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    overflow: hidden;
}

.lightbox-modal {
    position: relative;
    width: 100%;
    height: 100%;
    /* background: #fff; */
    border-radius: 8px;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.lightbox-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: transparent;
    color: #fff;
    font-size: 1.2em;
}

.lightbox-close-button {
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.lightbox-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.lightbox-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: 80vh;
}

.lightbox-controls {
    display: flex;
    justify-content: center;
    padding: 10px;
    position: absolute;
    width: 100%;
    top: 50%;
}
.lightbox-prev-button, .lightbox-next-button {
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5em;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    width: 30px;
    height: 70px;
}
.lightbox-prev-button {
    position: absolute;
    left: 20px;
    border-radius: 0 4px 4px 0;
}

.lightbox-next-button {
    position: absolute;
    right: 20px;
    border-radius: 4px 0 0 4px;
}
